
.crud-list-table {
  &.is-refreshed {
    position: relative;

    &::after {
      border-radius: var(--border-radius-s);
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      pointer-events: none;
      z-index: 5;
      background-color: rgba(0, 0, 0, 0);
      animation: pulse-list-table 1s infinite;
    }
  }

  th.orderable {
    cursor: pointer;
    transition: opacity 200ms ease, color 200ms ease;
    color: var(--c-inverse);
    &:hover {
      opacity: 0.8;
      color: var(--c-alt);
    }
  }

  th.is-current-ordering {
    color: var(--c-alt);
  }

  th:not(.orderable) {
    cursor: not-allowed;
    color: var(--c-inverse);
  }

  th.item-select-th {
    cursor: auto;
  }

  @-webkit-keyframes pulse-list-table {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    30% {
      background-color: rgba(0, 0, 0, 0.1);
    }
    100% {
      background-color: rgba(0, 0, 0, 0);
    }
  }
  @keyframes pulse-list-table {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    30% {
      background-color: rgba(0, 0, 0, 0.1);
    }
    100% {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .has-quick-edit {
    cursor: pointer;
  }
}
